import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src74448501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "common-redux-selectors"
    }}>{`Common Redux Selectors`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`@kineticdata/bundle-common`}</inlineCode>{` package also provides a handful of commonly used Redux selectors. They can be imported
at the top level of the bundle:`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import {
  selectCurrentKapp,
  selectAdminKapp,
  selectAdminKappSlug,
  selectQueueKappSlug,
  selectServicesKappSlug,
  // ... see common package index for comprehensive list.
} from '@kineticdata/bundle-common';
`}</code></pre>
    <p>{`It is not uncommon for one package to need information about another package and this is typically
configuration that is contained on the Kapp instance that represents that package. Frequently you will
need the slug of the Kapp for another package instance so that you can link to it.`}</p>
    <p>{`These selectors can be used directly in the `}<inlineCode parentName="p">{`useSelector`}</inlineCode>{` Redux hook or the `}<inlineCode parentName="p">{`mapStateToProps`}</inlineCode>{` argument
to the Redux `}<inlineCode parentName="p">{`connect(mapStateToProps)`}</inlineCode>{` function.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Example using React Redux \`connect\`
const mapStateToProps = state => ({
  currentKapp: selectCurrentKapp(state)
});

// Example using React Redux hooks.
const Component = () => {
  // Returns a Kapp model or null.
  const kapp = useSelector(selectCurrentKapp);

  return <div/>
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      